import { type MetaFunction, useLoaderData } from "@remix-run/react";
import Container from "~/components/layout/Container";
import { references } from "../_index";
import MekanikTesisatlarComponent from "../features/MekanikTesisatlar";
import References from "../features/References";
import Slogans from "../features/Slogans";
export const MekanikTesisatListesi = [
  {
    id: 1,
    title: "ISITMA TESİSATLARI",
    description: `
      <div class="text__block mb-40">
                                    <p class="text__block-desc"></p><h3>Isıtma Tesisatları Nedir?</h3>
<p>Isıtma tesisatları, binalarda sıcaklık kontrolünü sağlayan sistemlerin tümünü kapsar. Bu sistemler, iç mekanların ısınması için enerji kaynaklarından (doğalgaz, elektrik, güneş enerjisi vb.) yararlanarak, kullanıcılara konforlu bir ortam sunar. Isıtma tesisatları, binaların iç mekanlarında sıcaklık seviyelerinin kontrol edilmesini ve ideal yaşam koşullarının oluşturulmasını sağlar. Sağlıklı, verimli ve güvenli bir ısıtma sistemi, konforun yanı sıra enerji verimliliği ve çevre dostu bir kullanım sunar.</p>
<hr>
<h3>Isıtma Tesisatlarının Bileşenleri</h3>
<p>Isıtma tesisatları, bir dizi bileşenden oluşur. Bu bileşenlerin her biri, sistemin düzgün çalışması için hayati önem taşır. Isıtma sisteminin doğru kurulumu ve bakımı, uzun vadeli verimlilik ve güvenlik sağlar.</p>
<h4>Ana Bileşenler:</h4>
<ul>
<li><strong>Kazanlar</strong>: Sıcak su veya buhar üretmek için kullanılan cihazlardır. Kazanlar, genellikle doğalgaz, kömür, elektrik veya diğer enerji kaynaklarıyla çalışır.</li>
<li><strong>Isı Pompaları</strong>: Enerji verimliliği yüksek olan ısı pompaları, ortamdan ısı çekerek veya ortamın fazla ısınımlarını dışarı atarak, sıcaklık seviyesini kontrol eder.</li>
<li><strong>Radyatörler</strong>: Kazandan gelen sıcak suyu alarak, havaya yayan cihazlardır. Evin farklı bölgelerine ısıyı yayarak sıcak bir ortam sağlar.</li>
<li><strong>Borular ve Vanalar</strong>: Isıtma sisteminin suyu taşıyan boru hattı, sıcak suyu radyatörlere veya yerden ısıtma sistemlerine taşır. Vanalar ise sıcaklık ayarı yapılmasını sağlar.</li>
<li><strong>Termostatlar</strong>: Kullanıcıların oda sıcaklıklarını belirleyebileceği cihazlardır. Isıtma sisteminin istenilen sıcaklığa ulaşmasını ve sabit kalmasını sağlar.</li>
</ul>
<hr>
<h3>Isıtma Sistemi Türleri</h3>
<p>Isıtma tesisatları, farklı sistemlere sahip olabilir ve her sistem, kullanıcının ihtiyaçlarına göre seçilir. Isıtma tesisatının türü, binanın büyüklüğüne, izolasyonuna ve enerji verimliliğine göre değişir.</p>
<h4>1. <strong>Doğalgazlı Isıtma Sistemi</strong></h4>
<p>Doğalgazlı ısıtma, Türkiye’de en yaygın kullanılan ısıtma sistemlerinden biridir. Doğalgaz, hızlı ısınma, düşük maliyet ve çevre dostu özellikleriyle tercih edilir.</p>
<ul>
<li><strong>Kazanlı Sistemler</strong>: Doğalgazla çalışan kazanlar, suyu ısıtarak radyatörlere veya yerden ısıtma sistemlerine gönderir.</li>
<li><strong>Verimlilik ve Tasarruf</strong>: Doğalgazlı sistemler, doğru şekilde kullanıldığında enerji tasarrufu sağlar ve daha az karbon salınımına neden olur.</li>
</ul>
<h4>&nbsp;</h4>
<h4>2. <strong>Elektrikli Isıtma Sistemi</strong></h4>
<p>Elektrikli ısıtma sistemleri, elektrikle çalışan cihazlar aracılığıyla ısınma sağlar. Bu sistemler genellikle yerden ısıtma veya elektrikli radyatörler şeklinde kullanılabilir.</p>
<ul>
<li><strong>Verimli ve Hızlı Isınma</strong>: Elektrikli sistemler hızlı ısınma sağlar, ancak maliyet açısından daha pahalı olabilir.</li>
<li><strong>Enerji Tasarrufu</strong>: Elektrikli ısıtma sistemleri, doğru kontrol edildiğinde enerji tasarrufu sağlayabilir.</li>
</ul>
<h4>&nbsp;</h4>
<h4>3. <strong>Sıcak Su (Fancoil) Sistemleri</strong></h4>
<p>Fancoil sistemleri, sıcak suyun havayla birleşerek odalara yayılmasını sağlar. Kazanlardan gelen sıcak su, fanlı ünitelerle birlikte odalara yönlendirilir.</p>
<ul>
<li><strong>Konforlu Isınma</strong>: Sıcak suyun fanlarla odalara üflendiği bu sistem, homojen bir sıcaklık dağılımı sağlar.</li>
<li><strong>Esneklik</strong>: Bu sistemler, farklı alanlar için kolayca uyarlanabilir.</li>
</ul>
<h4>&nbsp;</h4>
<h4>4. <strong>Yerinde Isıtma (Yerden Isıtma Sistemi)</strong></h4>
<p>Yerden ısıtma, zemin altına döşenen borular aracılığıyla sıcak suyun dolaşmasını sağlayarak, zeminden ısıtma sağlar. Bu sistem, ısının zeminden yukarıya doğru yayılmasını sağlayarak daha verimli bir ısınma sağlar.</p>
<ul>
<li><strong>Yüksek Konfor</strong>: Zemin altındaki borular, sıcaklığın eşit bir şekilde dağılmasını sağlar.</li>
<li><strong>Estetik Avantajlar</strong>: Yerden ısıtma, radyatörlere ihtiyaç duymadığından estetik açıdan daha şık bir görünüme sahip olabilir.</li>
</ul>
<hr>
<h3>Isıtma Sistemi Kurulumu</h3>
<p>Isıtma tesisatının kurulumu, uzmanlık gerektiren bir süreçtir. Yanlış kurulum, verimlilik kaybına ve güvenlik risklerine yol açabilir. AL-DE Mühendislik, her tür ısıtma sisteminin profesyonel bir şekilde kurulumu için hizmet vermektedir. İşte adım adım ısıtma sistemi kurulumu:</p>
<ol>
<li><strong>Proje Planlaması</strong>: Bina özelliklerine, ihtiyaçlara ve bütçenize uygun ısıtma sistemi planlanır.</li>
<li><strong>Malzeme Seçimi</strong>: Kaliteli ve dayanıklı malzemeler seçilir. Isıtma boruları, radyatörler ve kazanlar gibi bileşenler, sistemin uzun ömürlü ve verimli olmasını sağlar.</li>
<li><strong>Kurulum</strong>: İhtiyaçlara göre en uygun sistem kurulumu yapılır. Sistem, doğru bağlantılarla güvenli bir şekilde kurulur.</li>
<li><strong>Test ve Devreye Alma</strong>: Sistem çalıştırılmadan önce tüm bağlantılar ve cihazlar test edilir. Isıtma sisteminin düzgün çalıştığından emin olunur.</li>
</ol>
<hr>
<h3>Isıtma Sistemi Bakımı</h3>
<p>Isıtma sistemlerinin verimli çalışabilmesi için düzenli bakım gereklidir. Isıtma tesisatındaki sorunlar erken tespit edilirse, büyük arızaların önüne geçilebilir ve enerji verimliliği sağlanabilir. AL-DE Mühendislik olarak, tüm ısıtma sistemleriniz için profesyonel bakım hizmeti sunuyoruz.</p>
<h4>Bakım Adımları:</h4>
<ul>
<li><strong>Kazan Bakımı</strong>: Kazanın verimli çalışabilmesi için düzenli bakım yapılır. Yılda bir kez kazan bakımı yapılması önerilir.</li>
<li><strong>Radyatör Temizliği</strong>: Radyatörlerin içinde biriken hava veya kir, ısınmayı engelleyebilir. Bu nedenle, radyatörlerin hava alması sağlanır.</li>
<li><strong>Sistem Testi</strong>: Sistemin genel durumu, vanalar ve borular kontrol edilir. Herhangi bir sızıntı veya arıza varsa, hemen onarılır.</li>
<li><strong>Termostat Ayarı</strong>: Termostatların doğru çalıştığından emin olunarak, kullanıcıların istediği sıcaklık seviyelerine ulaşması sağlanır.</li>
</ul>
<hr>
<h3>Isıtma Sistemi Verimliliği</h3>
<p>Isıtma sisteminin verimli çalışması, hem enerji tasarrufu sağlar hem de doğaya olan etkisini azaltır. AL-DE Mühendislik, ısıtma sistemlerinin verimli çalışması için gerekli tüm iyileştirmeleri yapar.</p>
<ul>
<li><strong>Yüksek Verimlilikli Kazanlar</strong>: Düşük enerji tüketimi ile yüksek verimlilik sağlanır.</li>
<li><strong>İzole Borular</strong>: Boruların iyi yalıtımı, sıcak suyun kaybını önler ve sistemin verimli çalışmasını sağlar.</li>
<li><strong>Enerji Verimli Termostatlar</strong>: Akıllı termostatlar, sıcaklık seviyelerini optimize eder ve enerji tasarrufu sağlar.</li>
</ul>
<hr>
<h3>Isıtma Sistemi Güvenliği</h3>
<p>Isıtma sistemlerinin güvenliği, doğru kurulumu ve bakımının yapılması ile sağlanır. AL-DE Mühendislik, her türlü ısıtma tesisatının güvenli bir şekilde kurulması için gerekli tüm önlemleri alır.</p>
<ul>
<li><strong>Gaz Kaçağı Tespiti</strong>: Doğalgazlı sistemlerde, gaz kaçağına karşı düzenli kontrol yapılır.</li>
<li><strong>Sıcaklık Kontrolü</strong>: Isıtma sisteminin sıcaklık limitleri doğru ayarlanarak, aşırı ısınma ve yangın riski engellenir.</li>
<li><strong>Periyodik Bakımlar</strong>: Düzenli bakım, sistemin güvenli ve verimli çalışmasını sağlar.</li>
</ul><p></p>
                                </div>
    `,
    image: "/assets/isitma-sistemleri.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 2,
    title: "PİS SU VE TEMİZ SU TESİSATLARI",
    description: `
      <div class="text__block mb-40">
                                    <p class="text__block-desc"></p><h3>Pis Su ve Temiz Su Tesisatları Nedir?</h3>
<p>Pis su ve temiz su tesisatları, binalarda suyun düzgün bir şekilde taşınması ve yönetilmesi için kurulan altyapı sistemleridir. Temiz su tesisatı, iç mekanlarda kullanılacak suyu bina içindeki cihazlara ve musluklara taşırken, pis su tesisatı ise atık suların güvenli bir şekilde binadan uzaklaştırılmasını sağlar. Her iki sistem de hijyen, sağlık ve çevre güvenliği açısından son derece önemli olup, doğru kurulumu ve bakımı yapılan tesisatlar uzun vadede yaşam alanlarının kalitesini artırır.</p>
<hr>
<h3>Temiz Su Tesisatları</h3>
<p>Temiz su tesisatı, binalarda içme suyu, banyo, mutfak, WC gibi alanlarda kullanılacak suyu sağlamak için kurulan sistemdir. Bu tesisat, suyun kaynağından bina içindeki kullanım noktalarına taşınmasını sağlar. Temiz su tesisatı, genellikle içme suyu standartlarına uygun, temiz ve sağlıklı suyun akışını sağlamak için tasarlanır.</p>
<h4>Temiz Su Tesisatının Bileşenleri</h4>
<ul>
<li><strong>Ana Su Hattı</strong>: Belediye veya su temin eden kurumdan gelen suyun bina içerisine girmesini sağlayan ana boru hattıdır.</li>
<li><strong>Bölgesel Dağıtım Hattı</strong>: Ana su hattından suyu dairelere ve diğer kullanımlara dağıtan borulardır.</li>
<li><strong>Musluklar ve Fittingler</strong>: Su dağıtımının son noktalarına kadar doğru akışı sağlamak için musluklar, vanalar ve diğer fittingler kullanılır.</li>
<li><strong>Sıhhi Tesisat Boruları</strong>: Temiz suyun taşınmasında kullanılan borular genellikle plastik (PVC, PEX), bakır veya çelikten yapılır. Her biri, farklı kullanım koşullarına göre seçilir.</li>
</ul>
<h4>&nbsp;</h4>
<h4>Temiz Su Tesisatının Kurulumu</h4>
<p>Temiz su tesisatının kurulumu, doğru boru yerleşimi ve bağlantı sistemleriyle yapılır. Su tesisatının her aşamasında sızıntıların önlenmesi için dikkatli bir işçilik gereklidir. AL-DE Mühendislik olarak, her projeye özel olarak en verimli tesisat çözümlerini sunuyor ve suyun güvenli bir şekilde taşınmasını sağlıyoruz.</p>
<h4>Temiz Su Tesisatının Bakımı</h4>
<p>Düzenli bakım, temiz su tesisatının ömrünü uzatır ve su sızıntılarını önler. Tesisat boruları zamanla kireçlenebilir veya tıkanabilir. Bu nedenle, periyodik olarak su tesisatını kontrol ettirmeniz önerilir. AL-DE Mühendislik, tesisat sisteminizin düzenli bakımını gerçekleştirerek, her zaman sorunsuz bir su akışı sağlar.</p>
<hr>
<h3>Pis Su Tesisatları</h3>
<p>Pis su tesisatı, kullanılmış suyun, atık suların ve diğer kirli suyun bina dışına taşınmasını sağlayan sistemdir. Pis su, banyo, tuvalet, mutfak ve diğer kullanım alanlarından gelir ve güvenli bir şekilde kanalizasyona yönlendirilmesi gerekir. Pis su tesisatı, hem sağlık açısından hem de çevre koruması açısından kritik öneme sahiptir.</p>
<h4>Pis Su Tesisatının Bileşenleri</h4>
<ul>
<li><strong>Atık Su Boruları</strong>: Pis suyu taşıyan ana boru hattıdır. Bu borular genellikle PVC veya beton malzemeden yapılır ve suyun güvenli bir şekilde dışarıya taşınmasını sağlar.</li>
<li><strong>Tuvalet ve Lavabo Bağlantıları</strong>: Bu bağlantılar, atık suyun boru hattına yönlendirilmesi için kullanılır. Herhangi bir tıkanıklık durumunda bu noktalar sorunun kaynağı olabilir.</li>
<li><strong>Kanalizasyon Bağlantıları</strong>: Pis su tesisatının nihai amacı, tüm atık suyu kanalizasyona veya uygun bir arıtma sistemine taşımaktır. Kanalizasyon bağlantıları, dışarıya doğru genişler ve şehre ait kanalizasyon sistemine bağlanır.</li>
</ul>
<h4>&nbsp;</h4>
<h4>Pis Su Tesisatının Kurulumu</h4>
<p>Pis su tesisatının kurulumu, genellikle bina planlarına uygun olarak yapılır. AL-DE Mühendislik olarak, her binanın gereksinimlerine göre özelleştirilmiş tesisat çözümleri sunuyoruz. Atık su boru hattının yerleşimi, suyun hızlı ve verimli bir şekilde taşınmasını sağlamak için doğru eğimle yapılmalıdır. Ayrıca, tıkanıklıkların önlenmesi için hatların temizliğine özen gösterilmelidir.</p>
<h4>Pis Su Tesisatının Bakımı</h4>
<p>Pis su tesisatının düzenli bakımı, kanalizasyonun tıkanmasını önler ve suyun akışının düzenli olmasını sağlar. Zamanla borularda biriken atıklar, kötü kokulara ve tıkanıklıklara yol açabilir. Bu nedenle, boru hatlarının periyodik olarak temizlenmesi önemlidir. AL-DE Mühendislik, pis su tesisatının bakımını yaparak, sızıntıların ve tıkanıklıkların önüne geçer.</p>
<hr>
<h3>Su Tesisatlarında Kullanılan Malzemeler</h3>
<p>Su tesisatlarında kullanılan malzemeler, sistemin verimliliği ve uzun ömürlü olması açısından büyük önem taşır. AL-DE Mühendislik, tesisatlarınızda yalnızca kaliteli malzemeler kullanarak güvenliği ve dayanıklılığı sağlar.</p>
<ul>
<li><strong>PVC Borular</strong>: Hem temiz su hem de pis su tesisatlarında yaygın olarak kullanılır. Dayanıklı, hafif ve uzun ömürlüdür.</li>
<li><strong>PEX Borular</strong>: Esnek yapıları sayesinde kolayca döşenebilir ve genellikle sıcak su tesisatlarında kullanılır.</li>
<li><strong>Bakır Borular</strong>: İçme suyu tesisatlarında kullanılır ve uzun ömürlüdür. Ayrıca, bakır boruların antibakteriyel özellikleri vardır.</li>
<li><strong>Çelik Borular</strong>: Genellikle büyük binalarda kullanılır ve yüksek dayanıklılık sağlar.</li>
</ul>
<hr>
<h3>Su Tesisatının Güvenliği</h3>
<p>Su tesisatının güvenliği, hem kullanıcılar hem de çevre açısından son derece önemlidir. Temiz suyun hijyenik koşullarda taşınması, atık suyun ise çevreye zarar vermemesi gerekir. AL-DE Mühendislik olarak, tesisatların her aşamasında güvenlik önlemleri alır ve her türlü yasal gerekliliği yerine getiririz. Ayrıca, suyun güvenli bir şekilde taşınmasını sağlamak için gerekli tüm sızdırmazlık testlerini yaparız.</p>
<hr>
<h3>Su Tesisatlarının Yasal Düzenlemelere Uygunluğu</h3>
<p>Su tesisatları, yerel yönetmeliklere ve sağlık standartlarına uygun olarak yapılmalıdır. AL-DE Mühendislik, tüm projelerinde bu düzenlemelere uyum sağlar ve tesisatların sağlıklı ve verimli çalışmasını garanti eder. Yerel su ve kanalizasyon yönetmeliklerine uygunluk, hem güvenli kullanım sağlar hem de çevreye olumsuz etkileri engeller.</p>
<hr>
<h3>Pis Su ve Temiz Su Tesisatlarının Faydaları</h3>
<ul>
<li><strong>Hijyen ve Sağlık</strong>: Temiz suyun güvenli bir şekilde taşınması ve pis suyun doğru şekilde uzaklaştırılması, sağlıklı bir yaşam alanı sağlar.</li>
<li><strong>Enerji Verimliliği</strong>: Doğru tesisat kurulumları, enerji tasarrufu sağlar ve suyun verimli kullanılmasına olanak tanır.</li>
<li><strong>Çevre Dostu</strong>: Atık suların doğru şekilde arıtılması, çevreye zarar verilmesini engeller.</li>
<li><strong>Uzun Ömürlü Sistemler</strong>: Kaliteli malzemelerle kurulum yapılan tesisatlar, uzun yıllar boyunca güvenli bir şekilde çalışır.</li>
</ul><p></p>
                                </div>
    `,
    image: "/assets/temiz-su-ve-atik-su-sistemleri.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 3,
    title: "KLİMA TESİSATLARI",
    description: `
      <div class="text__block mb-40">
                                    <p class="text__block-desc"></p><h3>Klima Tesisatı Nedir?</h3>
<p>Klima tesisatı, bir binanın iç mekanlarının havalandırılması ve iklimlendirilmesi amacıyla kurulan sistemlerin tümünü ifade eder. Bu tesisatlar, özellikle iç mekanlarda sıcaklık, nem ve hava kalitesini ideal seviyelerde tutmak için kullanılır. Klima tesisatları, konforlu bir yaşam alanı sağlamak, enerji verimliliğini artırmak ve hava sirkülasyonunu düzenlemek için büyük önem taşır. AL-DE Mühendislik olarak, her türlü klima tesisatını ihtiyacınıza uygun olarak projelendiriyor ve uyguluyoruz.</p>
<hr>
<h3>Klima Tesisatlarının Önemi</h3>
<p>Klima tesisatları, binaların iç mekanlarında sağlıklı ve konforlu bir ortam oluşturmak için gereklidir. Doğru klima sistemi ile havalandırma, nem kontrolü ve sıcaklık düzenlemesi yapılabilir. İyi bir klima tesisatı, sadece konfor sağlamaz; aynı zamanda enerji tasarrufu da sağlar. Binaların enerji verimliliği, doğru tesisat kurulumuyla önemli ölçüde artar.</p>
<ul>
<li><strong>Konforlu Bir Ortam</strong>: Sıcak yaz günlerinde serin, soğuk kış günlerinde ise sıcak bir ortam sağlar.</li>
<li><strong>Hava Kalitesinin İyileştirilmesi</strong>: İyi bir klima tesisatı, havadaki kirleticileri ve tozları filtreler, böylece sağlıklı bir iç ortam sunar.</li>
<li><strong>Enerji Verimliliği</strong>: Enerji verimliliği yüksek cihazlarla yapılan kurulum, elektrik faturalarını düşürür.</li>
</ul>
<hr>
<h3>Klima Tesisatlarının Temel Bileşenleri</h3>
<p>Klima tesisatları, birden fazla bileşenden oluşur ve her biri, sistemin verimli çalışmasını sağlamak için kritik bir rol oynar. Bu bileşenler aşağıda açıklanmıştır:</p>
<h4>1. Klima Ünitesi</h4>
<p>Klima ünitesi, havanın soğutulması, ısıtılması ve nemlendirilmesi işlemlerini yapan ana cihazdır. Klima ünitesinin doğru seçilmesi, sistemin verimliliği açısından büyük önem taşır. AL-DE Mühendislik olarak, ihtiyaçlarınıza göre en uygun klima ünitesini seçiyor ve kurulumunu yapıyoruz.</p>
<h4>2. Boru Hattı ve Drenaj Sistemi</h4>
<p>Klima tesisatında, soğutma veya ısıtma işlemini gerçekleştiren gazların taşınması için özel boru hatları kullanılır. Ayrıca, kondens suyu (yoğuşma) için drenaj sistemleri de tesisata dahil edilir. Boru hattı ve drenaj sistemi, klima ünitesinin verimli çalışabilmesi için doğru şekilde kurulmalıdır.</p>
<h4>3. Hava Kanalları (Kanallı Sistemler)</h4>
<p>Hava kanalları, soğutulmuş ya da ısıtılmış havayı odalara dağıtan boru sistemleridir. Kanal tipi klima tesisatları, özellikle büyük alanlarda hava akışını daha verimli hale getirir. Bu sistemler, farklı odalarda aynı anda ısıtma veya soğutma yapma imkânı sağlar.</p>
<h4>4. Elektrik Bağlantıları</h4>
<p>Klima sistemlerinin çalışması için doğru elektrik bağlantılarının yapılması gereklidir. Elektrik bağlantıları, güvenlik önlemleri göz önünde bulundurularak, profesyonel ekiplerimiz tarafından yapılır.</p>
<h4>5. Hava Filtreleri</h4>
<p>Klima sistemlerinde kullanılan hava filtreleri, ortamın hava kalitesini iyileştirmek için kritik öneme sahiptir. Bu filtreler, havadaki tozları, polenleri ve diğer kirleticileri tutar, böylece daha sağlıklı bir iç ortam sağlar.</p>
<hr>
<h3>Klima Tesisatının Kurulum Aşamaları</h3>
<p>Klima tesisatının kurulumu, uzmanlık gerektiren bir süreçtir. AL-DE Mühendislik olarak, her adımda titizlikle çalışarak en verimli klima tesisatını kuruyoruz. İşte klima tesisatının kurulum aşamaları:</p>
<h4>1. İhtiyaç Analizi ve Proje Tasarımı</h4>
<p>İlk olarak, mekanın büyüklüğü, kullanım amacı, iç ortam koşulları ve enerji verimliliği göz önünde bulundurularak ihtiyaç analizi yapılır. Bu analiz doğrultusunda, en uygun klima tesisatı tasarımı yapılır. Tesisatın boyutları, kapasitesi ve sistem bileşenleri, bu aşamada belirlenir.</p>
<h4>2. Cihaz Seçimi ve Sistem Tasarımı</h4>
<p>Müşterimizin ihtiyaçlarına göre en uygun klima cihazları seçilir. İklim koşulları, oda büyüklüğü, enerji verimliliği gibi kriterlere göre, sistemin tasarımı yapılır. Kanallı, duvar tipi, salon tipi, VRF/VRV gibi farklı klima sistemleri tasarım aşamasında seçilebilir.</p>
<h4>3. Montaj ve Kurulum</h4>
<p>Klima cihazları, boru hatları, elektrik bağlantıları ve hava kanalları montajı yapılır. AL-DE Mühendislik, uzman ekibiyle tüm tesisatın doğru bir şekilde montajını gerçekleştirir. Ayrıca, tüm sistemin güvenliğini sağlamak için gerekli testler yapılır.</p>
<h4>4. Sistem Testi ve Devreye Alma</h4>
<p>Kurulum tamamlandıktan sonra, tüm sistemlerin çalışabilirliği test edilir. Soğutma, ısıtma, hava akış kontrolü ve diğer sistem parametreleri test edilerek, cihazların verimli çalıştığından emin olunur. Devreye alma işlemi sonrasında sistem kullanılmaya başlanabilir.</p>
<h4>5. Bakım ve Servis Hizmeti</h4>
<p>Klima tesisatları, düzenli bakım gerektiren sistemlerdir. AL-DE Mühendislik, kurulum sonrası periyodik bakım hizmetleri sunar. Klima filtresi temizliği, gaz kontrolü, fan bakımı gibi işlemler, cihazların verimli çalışması için düzenli olarak yapılır.</p>
<hr>
<h3>Klima Tesisatının Yasal Düzenlemelere Uygunluğu</h3>
<p>Klima tesisatlarının kurulumu sırasında, yerel yönetmeliklere ve enerji verimliliği standartlarına uyulması gerekmektedir. AL-DE Mühendislik, tüm projelerde gerekli izinleri alır ve uygulamaları bu düzenlemelere uygun olarak gerçekleştirir. Ayrıca, çevre dostu soğutucu gazlar kullanarak, doğaya zarar vermemek için çevreye duyarlı çözümler sunuyoruz.</p>
<hr>
<h3>Klima Tesisatının Faydaları</h3>
<ul>
<li><strong>Konfor ve Hava Kalitesi</strong>: Sıcaklık ve nem seviyeleri ideal tutulur, hava kalitesi artar.</li>
<li><strong>Enerji Tasarrufu</strong>: Verimli sistemler sayesinde, enerji tüketimi azalır.</li>
<li><strong>Sağlıklı İç Mekan</strong>: Havadaki kirleticiler filtrelenerek, sağlıklı bir ortam sağlanır.</li>
<li><strong>Düşük Elektrik Tüketimi</strong>: Enerji verimli cihazlarla elektrik faturaları düşer.</li>
</ul><p></p>
                                </div>
    `,
    image: "/assets/klima-tesisatlari.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 4,
    title: "RADYANT ISITMA SİSTEMLERİ",
    description: `
    <div>
    <h3>Radyant Isıtma Sistemleri Nedir?</h3>  

<p>Radyant ısıtma sistemleri , ışıma prensibiyle çalışan ve mekanlarda eşsiz bir konfor sağlayan modern bir ısıtma çözümüdür. Bu sistemler, geleneksel radyatörlerin veya fanlı ünitelerin aksine, ısıyı doğrudan insanlara ve nesnelere yayar. Böylece, havanın değil, yüzeylerin ısınması sağlanır. Radyant ısıtma, özellikle endüstriyel tesisler, açık alanlar, spor salonları, seralar ve yüksek tavanlı mekanlar gibi geniş alanlarda tercih edilir.</p>

  <p>Radyant ısıtıcılar, elektrik, doğalgaz veya LPG gibi enerji kaynaklarını kullanarak çalışır. Yüksek verimlilik oranlarıyla dikkat çeker ve enerji tüketimini minimize eder. Ayrıca, sessiz çalışma özelliği ve estetik tasarımlarıyla iç mekan dekorasyonuna uyum sağlar.</p>
<h3>Radyant Isıtma Sistemlerinin Çalışma Prensibi</h3>  

<p>Radyant ısıtma sistemleri, güneşin dünyayı ısıtma prensibine benzer şekilde çalışır. Isıtıcılar, elektromanyetik dalgalar aracılığıyla çevreye kızılötesi (infrared) ışınlar yayar. Bu ışınlar, insan derisini, mobilyaları ve zemini doğrudan ısıtır. Isınan yüzeyler ise çevreye yavaş yavaş ısı yayarak mekanın sıcaklığını artırır. </p>

<p>Bu sistemler, havanın sirkülasyonunu gerektirmediği için toz, nem ve alerjen sorunlarını ortadan kaldırır. Ayrıca, yüksek tavanlı mekanlarda bile etkili bir şekilde çalışır ve ısı kayıplarını minimize eder. </p>
<h3>Radyant Isıtma Sistemlerinin Avantajları</h3>  

   <ol>
      <li>1- <b>Enerji Verimliliği:</b>  Havayı değil, yüzeyleri ısıtarak enerji tüketimini %30-50 oranında azaltır.</li>  
  
      <li>2- <b>Hızlı ve Etkili Isıtma:</b>  Kullanılan alana odaklanarak hızlı bir şekilde ısı sağlar.</li>  
  
      <li>3- <b>Sağlık Dostu:</b>  Hava sirkülasyonu yapmadığı için toz, nem ve alerjen sorunlarını önler.</li>  
  
      <li>4- <b>Esneklik:</b>  Farklı enerji kaynaklarıyla (elektrik, doğalgaz, LPG) çalışabilme özelliği sunar.</li>  
  
      <li>5- <b>Estetik Tasarım:</b>  Minimalist ve kompakt tasarımları, iç mekan estetiğini bozmaz.</li>  
  
      <li>6- <b>Sessiz Çalışma:</b>  Fan veya motor içermediği için sessiz bir ortam sağlar.</li>  
  
      <li>7- <b>Uzun Ömürlü:</b>  Kaliteli malzemelerle üretilen modeller, uzun süre sorunsuz çalışır.</li>  
   </ol>
    

<h3>Radyant Isıtma Sistemlerinin Bileşenleri  </h3>

    <ul>
      <li><strong>Isıtıcı Panel veya Elemanlar:</strong>  Elektrikli rezistans veya yanma odası aracılığıyla kızılötesi ışınlar yayan ana bileşen.</li>  
      <li><strong>Yansıtıcı Yüzeyler:</strong>  Isıyı maksimum verimlilikle yönlendiren yansıtıcı malzemeler.</li>  
      <li><strong>Kontrol Ünitesi:</strong>  Sıcaklık ayarlarını otomatikleştirir ve enerji tüketimini optimize eder.</li>  
      <li><strong>Montaj Aksesuarları:</strong>  Duvara, tavanda veya zemine montaj için kullanılan aparatlar.</li>  
      <li><strong>Emniyet Sistemleri:</strong>  Aşırı ısınma veya arıza durumunda cihazı koruyan sensörler.</li>
    </ul>
     

<h3>Radyant Isıtma Sistemleri İçin Hangi Durumlarda Tercih Edilir?   </h3>

   <ul>
      <li><strong>Endüstriyel Alanlar:</strong>  Fabrikalar, atölyeler ve depolar gibi geniş mekanlarda idealdir.</li>  
      <li><strong>Açık Alanlar:</strong>  Açık hava etkinlikleri, fuarlar veya inşaat alanlarında geçici ısıtma çözümleri sağlar.</li>  
      <li><strong>Yüksek Tavanlı Mekanlar:</strong>  Spor salonları, camiler ve konferans salonlarında etkili bir şekilde çalışır.</li>  
      <li><strong>Seracılık ve Tarım:</strong>  Seraların ısıtılmasında ve bitki büyümesini desteklemek için kullanılır.</li>  
      <li><strong>Konutlar ve Ofisler:</strong>  Odalara özel ısıtma ihtiyacı olan bireysel mekanlarda tercih edilir.</li>
   </ul>
     

<h3>Neden Profesyonel Radyant Isıtma Hizmeti Seçmelisiniz?   </h3>

<p>Radyant ısıtma sistemlerinin doğru şekilde seçilmesi, kurulması ve bakımı, sistemin verimli ve güvenli çalışmasını sağlar. <b>AL-DE Mühendislik</b>  olarak:   

    <ul>
      <li><strong>Uzman Ekip:</strong>  Tecrübeli mühendis ve teknisyenlerimiz, projenizin her aşamasında size destek olur.</li>  
      <li><strong>Kaliteli Malzemeler:</strong>  Dayanıklı ve verimli malzemeler kullanarak uzun ömürlü çözümler sunarız.</li>  
      <li><strong>Güvenlik ve Standartlar:</strong>  Tüm kurulumlarımız, ulusal ve uluslararası güvenlik standartlarına uygun şekilde gerçekleştirilir.</li>  
      <li><strong>Periyodik Bakım:</strong>  Düzenli bakım hizmetleri ile sisteminizin her zaman en iyi performansla çalışmasını sağlarız.</li>
    </ul>
     

<h3>Konforlu ve Verimli Bir Isıtma Deneyimi: Radyant Isıtma Sistemleri</h3>  

<p>Radyant ısıtma sistemleri, geniş mekanlar için mükemmel bir ısıtma çözümü sunar. Hem ekonomik hem de çevre dostu olan bu sistemler, hızlı ve etkili bir şekilde ısı sağlar. <b>AL-DE Mühendislik</b>  olarak, radyant ısıtma sistemlerinde uzmanlaşmış ekibimizle size danışmanlık, tasarım, kurulum ve bakım hizmetleri sunuyoruz.   </p>

<p>Her mekan için ideal ısıtma çözümü – <b>AL-DE Mühendislik</b> ile!  </p>
    </div>  

    `,
    image: "/assets/radyant-isitma-sistemleri.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 5,
    title: "SICAK HAVA ÜRETEÇLERİ",
    description: `
      <div>
      <h3>Sıcak Hava Üreteçleri Nedir?   </h3>

<p>Sıcak hava üreteçleri , mekanların hızlı ve etkili bir şekilde ısıtılmasını sağlayan verimli bir ısıtma çözümüdür. Özellikle endüstriyel tesisler, atölyeler, seralar, depolar, spor salonları ve açık alanlar gibi geniş mekanlarda tercih edilir. Bu sistemler, elektrik, doğalgaz, LPG veya dizel gibi enerji kaynaklarını kullanarak havayı ısıtır ve çevreye sıcak hava yayar. 
</p>

<p>Sıcak hava üreteçleri, geleneksel ısıtma yöntemlerine göre daha hızlı ve esnek bir çözüm sunar. Modern modeller, yüksek verimlilik, düşük işletme maliyeti ve çevre dostu teknolojilerle donatılmıştır. Ayrıca, kolay taşınabilirlik ve montaj özellikleriyle farklı kullanım senaryolarına uyum sağlar. 
<h3>Sıcak Hava Üreteçlerinin Çalışma Prensibi   </h3>

<p>  Sıcak hava üreteçleri, enerji kaynağını (elektrik, doğalgaz, LPG veya dizel) yakarak veya elektrikle ısıtma elemanlarını çalıştırarak havayı ısıtır. Isınan hava, fanlar aracılığıyla çevreye dağıtılır. Bu süreçte, üreteçler hem iç mekanlarda hem de açık alanlarda etkili bir şekilde çalışır.   </p>

<p>Modern modellerde bulunan termostatlar , otomatik ateşleme sistemleri  ve emniyet sensörleri , kullanımı kolaylaştırır ve enerji tüketimini optimize eder. Ayrıca, bazı üreteçlerde bulunan havalandırma modu , sadece havayı dolaştırarak serinletme imkanı da sunar.  </p>
<h3>Sıcak Hava Üreteçlerinin Avantajları   </h3>

    <ol>
      <li>1- <b>Hızlı Isıtma:</b> 
      Geniş mekanları kısa sürede ısıtır ve konforlu bir ortam oluşturur. 
      </li>
      <li>2- <b>Esnek Kullanım:</b> 
      Endüstriyel tesislerden açık alanlara kadar farklı mekanlarda kullanılabilir. 
      </li>
      <li>3- <b>Çok Yönlülük:</b> 
      Elektrik, doğalgaz, LPG veya dizel gibi farklı enerji kaynaklarıyla çalışır. 
      </li>
      <li>4- <b>Ekonomik:</b> 
      Düşük işletme maliyeti ve yüksek verimlilik oranlarıyla uzun vadede tasarruf sağlar. 
      </li>
      <li>5- <b>Taşınabilirlik:</b> 
      Mobil modeller, farklı alanlara kolayca taşınabilir ve kurulabilir. 
      </li>
      <li>6- <b>Güvenlik:</b> 
      Otomatik güvenlik sistemleri ve sensörlerle donatılmıştır. Alev söndüğü veya aşırı ısınma durumunda cihaz otomatik olarak kapanır. 
      </li>
      <li>7- <b>Çevre Dostu Modeller:</b> 
      Doğalgaz veya LPG kullanan modeller, çevreye zarar veren emisyonları minimize eder. 
      </li>
    </ol>
     

<h3>Sıcak Hava Üreteçlerinin Bileşenleri   </h3>

    <ul>
      <li><strong>Isıtma Elemanı:</strong>  Elektrikli rezistans veya yanma odası aracılığıyla havayı ısıtan ana bileşen.</li>  
      <li><strong>Fan Sistemi:</strong>  Isınan havayı çevreye dağıtan güçlü fanlar.</li>  
      <li><strong>Yakıt Deposu (Dizel Modeller):</strong>  Yakıtın depolandığı ve sisteme aktarıldığı bölüm.</li>  
      <li><strong>Termostat ve Kontrol Paneli:</strong>  Sıcaklık ayarlarını otomatikleştirir ve enerji tüketimini optimize eder.</li>
      <li><strong>Emniyet Sistemleri:</strong>  Gaz kaçağı, alev söndüğü veya aşırı ısınma gibi durumlarda cihazı koruyan sensörler.</li>
    </ul>
     

<h3>Sıcak Hava Üreteçleri İçin Hangi Durumlarda Tercih Edilir?   </h3>

    <ul>
      <li><strong>Endüstriyel Alanlar:</strong>  Fabrikalar, atölyeler ve depolar gibi geniş mekanlarda idealdir.</li>  
      <li><strong>Açık Alanlar:</strong>  Açık hava etkinlikleri, fuarlar veya inşaat alanlarında geçici ısıtma çözümleri sağlar.</li>  
      <li><strong>Seracılık ve Tarım:</strong>  Seraların ısıtılmasında ve bitki büyümesini desteklemek için kullanılır.</li>  
      <li><strong>Mevcut Sistemlerin Yetersiz Olduğu Durumlar:</strong>  Merkezi ısıtma sisteminin yetersiz kaldığı mekanlarda tamamlayıcı bir ısı kaynağı olarak tercih edilir.</li>  
      <li><strong>Acil Durumlar:</strong>  Elektrik kesintileri veya ani soğuk hava dalgalarında hızlı çözüm sağlar.</li>
    </ul>
     

<h3>Neden Profesyonel Sıcak Hava Üreteci Hizmeti Seçmelisiniz?   </h3>

<p>Sıcak hava üreteçlerinin doğru şekilde seçilmesi, kurulması ve bakımı, sistemin verimli ve güvenli çalışmasını sağlar. <b>AL-DE Mühendislik</b>  olarak:   </p>

    <ul>
      <li><strong>Uzman Ekip:</strong>  Tecrübeli mühendis ve teknisyenlerimiz, projenizin her aşamasında size destek olur.</li>
      <li><strong>Kaliteli Malzemeler:</strong>  Dayanıklı ve verimli malzemeler kullanarak uzun ömürlü çözümler sunarız.</li>
      <li><strong>Güvenlik ve Standartlar:</strong>  Tüm kurulumlarımız, ulusal ve uluslararası güvenlik standartlarına uygun şekilde gerçekleştirilir.</li>
      <li><strong>Periyodik Bakım:</strong>  Düzenli bakım hizmetleri ile sisteminizin her zaman en iyi performansla çalışmasını sağlarız.</li>
    </ul>
     

<h3>Hızlı ve Verimli Isıtma İçin Sıcak Hava Üreteçleri   </h3>

<p>Sıcak hava üreteçleri, geniş mekanlar için mükemmel bir ısıtma çözümü sunar. Hem ekonomik hem de esnek olan bu sistemler, hızlı ve etkili bir şekilde ısı sağlar. <b>AL-DE Mühendislik</b>  olarak, sıcak hava üreteçlerinde uzmanlaşmış ekibimizle size danışmanlık, tasarım, kurulum ve bakım hizmetleri sunuyoruz.   </p>

<p>Her mekan için ideal ısıtma çözümü – <b>AL-DE Mühendislik</b> ile!  </p>
      </div>
    `,
    image: "/assets/sicak-hava-ureteci.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 6,
    title: "KATI YAKITLI KAZAN SİSTEMLERİ",
    description: `
      <div>
      <h3>Katı Yakıtlı Kazan Sistemleri Nedir?   </h3>

<p>Katı yakıtlı kazan sistemleri , odun, kömür, briket veya pellet gibi katı yakıtları kullanarak ısı üreten verimli ve ekonomik bir ısıtma çözümüdür. Özellikle doğalgaz şebekesinin bulunmadığı bölgelerde, kırsal alanlarda veya alternatif enerji kaynaklarına ihtiyaç duyulan yerlerde tercih edilir. Bu sistemler, hem konutlar hem de ticari tesisler için idealdir ve çevre dostu yakıtlarla sürdürülebilir bir yaşam sunar. </p>

<p>Katı yakıtlı kazanlar, geleneksel ısıtma yöntemlerine göre daha gelişmiş teknolojilerle donatılmıştır. Modern modeller, otomatik yanma kontrolü, yüksek verimlilik ve düşük emisyon özellikleriyle dikkat çeker. Ayrıca, uzun süreli ısı sağlama kapasitesiyle mekanların sürekli ısınmasını garanti eder. </p>
<h3>Katı Yakıtlı Kazan Sistemlerinin Çalışma Prensibi   </h3>

<p>Katı yakıtlı kazan sistemleri, katı yakıtın yanmasıyla üretilen ısıyı suya aktarır. Isınan su, radyatörler veya yerden ısıtma sistemleri aracılığıyla mekanı ısıtırken, aynı zamanda sıcak su ihtiyacını da karşılar. Modern kazanlar, otomatik besleme sistemleri , termostatik kontroller  ve emniyet sensörleri  ile donatılmıştır. Bu özellikler, kullanım kolaylığını artırır ve enerji tüketimini optimize eder. </p>

<p>Yüksek verimlilikli tasarımları sayesinde, yakıtın tamamından maksimum fayda sağlanır. Ayrıca, çevreye zarar vermeyen biyokütle (örneğin pellet) kullanımıyla karbon ayak izi minimize edilir. </p>
<h3>Katı Yakıtlı Kazan Sistemlerinin Avantajları   </h3>

    <ol>
      <li>1- <b>Ekonomik Yakıt Seçenekleri: </b>
      Odun, kömür, briket veya pellet gibi ucuz ve kolay temin edilebilir yakıtlar kullanılır.
      </li>
      <li>2- <b>Çevre Dostu: </b>
      Biyokütle yakıtlar (örneğin pellet) kullanımıyla çevreye zarar vermez ve sürdürülebilir bir enerji kaynağıdır. 
      </li>
      <li>3- <b>Uzun Süreli Isıtma: </b>
      Tek bir yakıt yüküyle saatlerce ısı sağlar, özellikle soğuk iklimlerde idealdir. 
      </li>
      <li>4- <b>Esneklik: </b>
      Farklı katı yakıtlarla çalışabilme özelliği, kullanıcıya esneklik sunar. 
      </li>
      <li>5- <b>Güvenilirlik: </b>
      Elektrik kesintilerinden etkilenmez ve bağımsız bir ısıtma çözümü sunar. 
      </li>
      <li>6- <b>Kolay Bakım: </b>
      Modern modeller, düzenli temizlik ve basit bakım gerektirir. 
      </li>
    </ol>
    

<h3>Katı Yakıtlı Kazan Sistemlerinin Bileşenleri  </h3>

    <ul>
      <li><strong>Kazan Ünitesi:</strong>  Katı yakıtı yakarak suyu ısıtan ana bileşen. Yüksek verimlilikli modeller, enerji tasarrufu sağlar.</li>
      <li><strong>Yakıt Depolama Alanı:</strong>  Kömür, odun veya pellet gibi yakıtların depolandığı bölüm. Otomatik besleme sistemleri bu alanı destekler.</li>
      <li><strong>Boru ve Radyatör Sistemi:</strong>  Isınan suyun mekanlara taşındığı boru hattı ve radyatörler.</li>
      <li><strong>Termostat ve Kontrol Paneli:</strong>  Sıcaklık ayarlarını otomatikleştirir ve enerji tüketimini optimize eder.</li>
      <li><strong>Emniyet Sistemleri:</strong>  Aşırı basınç, sıcaklık veya gaz kaçağı durumunda cihazı koruyan sensörler.</li>
    </ul>
     

<h3>Katı Yakıtlı Kazan Sistemleri İçin Hangi Durumlarda Tercih Edilir?   </h3>

    <ul>
      <li><strong>Doğalgaz Altyapısının Olmadığı Bölgeler:</strong>  Kırsal alanlar, yazlıklar veya yeni yerleşim bölgelerinde idealdir.  
      <li><strong>Alternatif Enerji Kaynağı Arayanlar:</strong>  Doğal ve yenilenebilir enerji kaynaklarını tercih edenler için uygundur.  
      <li><strong>Mevcut Sistemlerin Yetersiz Olduğu Durumlar:</strong>  Merkezi ısıtma sisteminin yetersiz kaldığı mekanlarda tamamlayıcı bir ısı kaynağı olarak kullanılır.  
      <li><strong>Ekonomik Çözüm Arayanlar:</strong>  Ucuz ve kolay temin edilebilir yakıtlarla uzun süreli ısı sağlar.</li>
    </ul>
     

<h3>Neden Profesyonel Katı Yakıtlı Kazan Hizmeti Seçmelisiniz?   </h3>

<p>Katı yakıtlı kazan sistemlerinin doğru şekilde seçilmesi, kurulması ve bakımı, sistemin verimli ve güvenli çalışmasını sağlar. <b>AL-DE Mühendislik</b>  olarak:   </p>

<ul>
      <li><strong>Uzman Ekip:</strong>  Tecrübeli mühendis ve teknisyenlerimiz, projenizin her aşamasında size destek olur.</li>  
      <li><strong>Kaliteli Malzemeler:</strong>  Dayanıklı ve verimli malzemeler kullanarak uzun ömürlü çözümler sunarız.</li>  
      <li><strong>Güvenlik ve Standartlar:</strong>  Tüm kurulumlarımız, ulusal ve uluslararası güvenlik standartlarına uygun şekilde gerçekleştirilir.</li>  
      <li><strong>Periyodik Bakım:</strong>  Düzenli bakım hizmetleri ile sisteminizin her zaman en iyi performansla çalışmasını sağlarız.</li>
</ul>
    

<h4>Sürdürülebilir ve Ekonomik Bir Isıtma Çözümü: Katı Yakıtlı Kazan Sistemleri   </h4>

<p>Katı yakıtlı kazan sistemleri, doğalgaz altyapısının olmadığı bölgelerde veya alternatif enerji kaynaklarına ihtiyaç duyulan yerlerde mükemmel bir ısıtma çözümü sunar. Hem ekonomik hem de çevre dostu olan bu sistemler, uzun süreli ve güvenilir bir şekilde ısı sağlar. <b>AL-DE Mühendislik</b>  olarak, katı yakıtlı kazan sistemlerinde uzmanlaşmış ekibimizle size danışmanlık, tasarım, kurulum ve bakım hizmetleri sunuyoruz.   </p>

<p>Konforlu ve sürdürülebilir bir yaşam için – <b>AL-DE Mühendislik</b> ile!  </p>
      </div>
    `,
    image: "/assets/kati-yakitli-kazan.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 7,
    title: "DOĞALGAZLI SOBA SİSTEMLERİ",
    description: `
    <div>
    <h3>Doğalgazlı Soba Sistemleri Nedir?   </h3>

<p>Doğalgazlı soba sistemleri , özellikle küçük mekanlar veya bireysel odalar için tasarlanmış, verimli ve çevre dostu bir ısıtma çözümüdür. Doğalgaz şebekesinden beslenen bu sistemler, geleneksel kömür veya odun yakıtlı sobalara göre daha temiz, güvenli ve ekonomik bir alternatif sunar. Özellikle apartman daireleri, ofisler, mağazalar veya geçici kullanım alanları gibi mekanlarda tercih edilir. </p>

<p>Doğalgazlı sobalar, doğrudan odaya monte edilerek hızlı ve etkili bir şekilde ısı sağlar. Ayrıca, modern tasarımları ve kompakt yapılarıyla iç mekan estetiğini bozmaz. Akıllı kontrol özellikleriyle de enerji tüketimini optimize eder, size konforlu bir deneyim sunar. </p>
<h3>Doğalgazlı Soba Sistemlerinin Çalışma Prensibi  </h3>

<p>Doğalgazlı soba sistemleri, doğalgazın yanmasıyla üretilen ısıyı doğrudan odaya yayar. İçerisinde bulunan yanma odası, gazı yüksek verimlilikle yakarak maksimum ısı üretir. Üretilen ısı, sobanın ön paneli veya fanlar aracılığıyla çevreye dağıtılır. Modern modellerde bulunan termostatlar  ve otomatik ateşleme sistemleri , kullanımı kolaylaştırır ve enerji tasarrufu sağlar. </p>

<p>Bu sistemler, doğalgazın temiz yanma özelliğinden dolayı çevreye zarar vermez ve karbon ayak izini minimize eder. Ayrıca, geleneksel sobalara kıyasla daha az bakım gerektirir. </p>
<h3>Doğalgazlı Soba Sistemlerinin Avantajları  </h3>

    <ol>
      <li>1- <b>Ekonomik Isıtma:</b>  Doğalgaz, en uygun fiyatlı enerji kaynaklarından biridir. Düşük işletme maliyeti ile uzun vadede tasarruf sağlar.</li> 
  
      <li>2- <b>Hızlı ve Etkili Isınma:</b>  Odaları kısa sürede ısıtır ve konforlu bir ortam oluşturur.</li> 
  
      <li>3- <b>Çevre Dostu:</b>  Temiz yanma özelliği sayesinde havaya zararlı emisyon salınımı yapmaz.</li> 
  
      <li>4- <b>Kompakt ve Estetik Tasarım:</b>  Modern ve minimalist tasarımları, iç mekan dekorasyonuna uyum sağlar.</li> 
  
      <li>5- <b>Güvenlik:</b>  Otomatik güvenlik sistemleri ve sensörlerle donatılmıştır. Gaz kaçağı veya aşırı ısınma durumunda cihaz otomatik olarak kapanır.</li> 
  
      <li>6- <b>Kolay Kurulum:</b>  Mevcut doğalgaz altyapısına kolayca bağlanabilir ve minimum alan kaplar.</li> 
    </ol>
     

<h3>Doğalgazlı Soba Sistemlerinin Bileşenleri   </h3> 

    <ul>
      <li><strong>Soba Ünitesi:</strong>  Doğalgazı yakarak ısı üreten ana bileşen. Modern tasarımları ve fanlı modelleriyle dikkat çeker.</li>
      <li><strong>Doğalgaz Bağlantısı:</strong>  Şebekeden gelen doğalgazı sobaya taşıyan boru sistemi.</li>
      <li><strong>Yanma Odası:</strong>  Doğalgazın yüksek verimlilikle yakıldığı bölüm.</li>
      <li><strong>Termostat ve Kontrol Paneli:</strong>  Sıcaklık ayarlarını otomatikleştirir ve enerji tüketimini optimize eder.</li>
      <li><strong>Emniyet Sistemleri:</strong>  Gaz kaçağı, alev söndüğü veya aşırı ısınma gibi durumlarda cihazı koruyan sensörler.</li>
    </ul>
     

<h3>Doğalgazlı Soba Sistemleri İçin Hangi Durumlarda Tercih Edilir?   </h3>

    <ul>
      <li><strong>Küçük Mekanlar:</strong>  Tek bir oda veya küçük alanların ısıtılmasında idealdir.</li>  
      <li><strong>Bireysel Isıtma İhtiyacı:</strong>  Tüm evi ısıtmak yerine belirli bir alanı ısıtmak istediğinizde kullanılır.</li>  
      <li><strong>Geçici Kullanım Alanları:</strong>  Ofisler, mağazalar, depolar veya yazlıklar gibi mekanlarda pratik bir çözümdür.</li>  
      <li><strong>Mevcut Sistemlerin Yetersiz Olduğu Durumlar:</strong>  Merkezi ısıtma sisteminin yetersiz kaldığı odalarda tamamlayıcı bir ısı kaynağı olarak tercih edilir.</li>
    </ul>
     

<h3>Neden Profesyonel Doğalgazlı Soba Hizmeti Seçmelisiniz?   </h4>

<p>Doğalgazlı soba sistemlerinin doğru şekilde seçilmesi, kurulması ve bakımı, sistemin verimli ve güvenli çalışmasını sağlar. <b>AL-DE Mühendislik</b>  olarak:   </p>

    <ul>
      <li><strong>Uzman Ekip:</strong>  Tecrübeli mühendis ve teknisyenlerimiz, projenizin her aşamasında size destek olur.</li>
      <li><strong>Kaliteli Malzemeler:</strong>  Dayanıklı ve verimli malzemeler kullanarak uzun ömürlü çözümler sunarız.</li>
      <li><strong>Güvenlik ve Standartlar:</strong>  Tüm kurulumlarımız, ulusal ve uluslararası güvenlik standartlarına uygun şekilde gerçekleştirilir.</li>
      <li><strong>Periyodik Bakım:</strong>  Düzenli bakım hizmetleri ile sisteminizin her zaman en iyi performansla çalışmasını sağlarız.</li>
    </ul>
     

<h3>Konforlu ve Ekonomik Bir Yaşam İçin Doğalgazlı Soba Sistemleri   </h3>
<p>Doğalgazlı soba sistemleri, küçük mekanlar için mükemmel bir ısıtma çözümü sunar. Hem ekonomik hem de çevre dostu olan bu sistemler, hızlı ve etkili bir şekilde ısı sağlar. <b>AL-DE Mühendislik</b>  olarak, doğalgazlı soba sistemlerinde uzmanlaşmış ekibimizle size danışmanlık, tasarım, kurulum ve bakım hizmetleri sunuyoruz.   </p>
<p>Isıtmada yenilikçi çözümler – <b>AL-DE Mühendislik</b> ile!  </p>
</div>`,
    image: "/assets/dogalgazli-soba.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 8,
    title: "VRF SİSTEMLERİ",
    description: `<div class="text__block mb-40">
                                    <p class="text__block-desc"></p><h3>VRF Sistemi Nedir?</h3>
<p>VRF (Variable Refrigerant Flow) sistemi, ticari ve büyük ölçekli binalarda ısıtma ve soğutma ihtiyaçlarını karşılayan, son derece verimli ve esnek bir iklimlendirme çözümüdür. Bu sistem, birden fazla iç üniteyi tek bir dış üniteye bağlayarak her odada bağımsız sıcaklık kontrolü sağlar. VRF sistemleri, enerji verimliliği, düşük işletme maliyetleri ve yüksek konfor seviyeleri sunarak, kullanıcıların hem ısıtma hem de soğutma ihtiyaçlarını tek bir sistem üzerinden çözmelerini sağlar.</p>
<hr>
<h3>VRF Sistemlerinin Çalışma Prensibi</h3>
<p>VRF sistemleri, dış üniteye bağlı çok sayıda iç üniteyle çalışır. Her iç ünite, odanın veya alanın sıcaklık ihtiyacına göre bağımsız olarak çalışabilir. Dış ünite, iç ünitelerin ihtiyacı olan soğutucu akışkanın miktarını ayarlayarak, her alandaki sıcaklık kontrolünü optimize eder.</p>
<ul>
<li><strong>Değişken Soğutucu Akışkan Teknolojisi</strong>: VRF sistemleri, soğutucu akışkanın miktarını değiştirerek, her iç üniteye ihtiyaç duyduğu kadar enerji sağlar. Bu sayede sistem, daha az enerji tüketir ve daha verimli çalışır.</li>
<li><strong>Isı Geri Kazanım Özelliği</strong>: Bazı VRF sistemleri, bir odada ısıtma yaparken, başka bir odada soğutma yapılmasına olanak tanır. Bu sayede enerji tasarrufu sağlanır.</li>
</ul>
<hr>
<h3>VRF Sisteminin Avantajları</h3>
<p>VRF sistemlerinin birçok avantajı vardır, özellikle verimlilik ve esneklik açısından oldukça caziptir. Bu sistem, farklı bina türleri için ideal bir ısıtma ve soğutma çözümüdür.</p>
<h4>1- <b>Enerji Verimliliği</b></h4>
<p>VRF sistemleri, geleneksel HVAC sistemlerine kıyasla çok daha az enerji tüketir. Değişken soğutucu akışkan teknolojisi sayesinde, sistem sadece ihtiyaç duyulan enerji miktarını sağlar. Bu, işletme maliyetlerini düşürür ve çevreye olan etkisini azaltır.</p>
<h4>2. <strong>Esneklik ve Özelleştirme</strong></h4>
<p>VRF sistemleri, her iç ünitenin bağımsız olarak çalışmasını sağlayarak, her odanın ihtiyacına göre sıcaklık ayarı yapılabilir. Bu, özellikle ofis binaları, oteller ve ticari alanlar için mükemmel bir esneklik sunar.</p>
<h4>3. <strong>Yüksek Konfor Seviyesi</strong></h4>
<p>VRF sistemleri, odadaki sıcaklık değişimlerini minimize ederek, her odada konforlu bir ortam yaratır. Havanın hızla soğutulması veya ısıtılması yerine, yavaş ve istikrarlı bir sıcaklık değişimi sağlar.</p>
<h4>4. <strong>Daha Az Yer Kaplayan Dış Ünite</strong></h4>
<p>VRF sistemlerinin dış üniteleri, geleneksel merkezi klima sistemlerine göre daha küçük ve daha az yer kaplar. Bu, binaların dış cephelerine estetik açıdan daha uygun bir çözüm sunar.</p>
<hr>
<h3>VRF Sisteminin Bileşenleri</h3>
<p>VRF sistemi, birkaç temel bileşenden oluşur. Her bileşen, sistemin verimli çalışmasını ve uzun ömürlü olmasını sağlamak için özel olarak tasarlanmıştır.</p>
<h4>1. <strong>Dış Ünite (Kompressör Ünitesi)</strong></h4>
<p>Dış ünite, soğutucu akışkanı kompresör aracılığıyla iç ünitelerin ihtiyaçlarına göre pompalar. Dış ünite, tüm sisteme güç sağlar ve VRF sisteminin verimliliğini belirler.</p>
<h4>2. <strong>İç Üniteler</strong></h4>
<p>İç üniteler, sistemin bulunduğu alanların sıcaklık ihtiyacına göre çalışır. Her iç ünite, odaya göre bağımsız olarak soğutma veya ısıtma yapabilir.</p>
<ul>
<li><strong>Duvar Tipi İç Ünite</strong>: Genellikle duvara monte edilir ve düşük gürültüyle çalışır.</li>
<li><strong>Kaset Tipi İç Ünite</strong>: Tavan arasına yerleştirilen ve odanın dört bir tarafından hava üfleyen üniteler.</li>
<li><strong>Yer Tipi İç Ünite</strong>: Zemin seviyesinde çalışan, ısıtma ve soğutma sağlayan üniteler.</li>
</ul>
<h4>&nbsp;</h4>
<h4>3. <strong>Soğutucu Akışkan (Freon)</strong></h4>
<p>VRF sistemlerinde kullanılan soğutucu akışkan, enerji taşıyıcı bir madde olarak görev görür. Soğutucu akışkan, dış üniteden iç üniteleri soğutmak veya ısıtmak için gerekli enerjiyi taşır.</p>
<h4>4. <strong>Kontrol Sistemleri</strong></h4>
<p>VRF sistemleri, gelişmiş dijital kontrol sistemleri ile yönetilir. Bu sistemler, iç ünitelerin sıcaklıklarını ve çalışma modlarını (soğutma/ısıtma) izleyerek her odanın ihtiyacına göre enerji dağılımını optimize eder.</p>
<hr>
<h3>VRF Sisteminin Kurulumu</h3>
<p>VRF sisteminin kurulumu, uzmanlık ve deneyim gerektiren bir süreçtir. Sistem kurulumu sırasında dikkat edilmesi gereken bazı önemli noktalar bulunmaktadır.</p>
<h4>1. <strong>Proje Tasarımı ve Planlama</strong></h4>
<p>İlk adım, kullanıcının ihtiyaçlarını anlamak ve bu ihtiyaçlara en uygun VRF sistemini tasarlamaktır. Bu aşama, binanın büyüklüğüne, iç mekan düzenine ve enerji verimliliği hedeflerine göre belirlenir.</p>
<h4>2. <strong>Altyapı Hazırlığı</strong></h4>
<p>VRF sistemlerinin kurulumu sırasında, iç ünitelerin yerleştirileceği alanlar ve boru hatlarının geçeceği yollar önceden belirlenir. Bu aşama, düzgün bir kurulum için oldukça önemlidir.</p>
<h4>3. <strong>Montaj ve Bağlantılar</strong></h4>
<p>İç ve dış üniteler doğru bir şekilde montelenir ve soğutucu akışkan boruları birbirine bağlanır. Ayrıca, tüm elektriksel bağlantılar ve kontrol sistemleri doğru şekilde yerleştirilir.</p>
<h4>4. <strong>Sistemin Test Edilmesi</strong></h4>
<p>Kurulum tamamlandıktan sonra, sistemin tüm bileşenleri test edilir. Sızıntılar, elektriksel hatalar ve verimlilik kontrolleri yapılır. Sistem, düzgün çalışmaya başladıktan sonra kullanıcıya teslim edilir.</p>
<hr>
<h3>VRF Sistemi Bakımı</h3>
<p>VRF sistemlerinin verimli çalışabilmesi için düzenli bakım gereklidir. Bu bakım işlemleri, sistemin uzun ömürlü ve verimli çalışmasını sağlamak için çok önemlidir.</p>
<h4>1. <strong>Soğutucu Akışkan Kontrolü</strong></h4>
<p>Soğutucu akışkanın seviyesi düzenli olarak kontrol edilmelidir. Eksik akışkan, sistemin verimliliğini azaltabilir.</p>
<h4>2. <strong>Filtre Temizliği</strong></h4>
<p>İç ünitelerdeki filtrelerin temizlenmesi, havalandırma verimliliğini artırır ve havanın kalitesini iyileştirir.</p>
<h4>3. <strong>Dış Ünite Bakımı</strong></h4>
<p>Dış ünite, hava koşullarına doğrudan maruz kaldığı için düzenli temizlik ve bakım gerektirir. Dış ünite fanlarının temizlenmesi ve soğutucu akışkanın kontrol edilmesi gerekmektedir.</p>
<h4>4. <strong>Elektriksel Sistem Kontrolleri</strong></h4>
<p>Elektriksel sistemlerin düzgün çalışması için periyodik kontroller yapılmalıdır. Elektrik bağlantıları ve kompresör kontrolü, sistemin güvenli çalışmasını sağlar.</p>
<hr>
<h3>VRF Sisteminin Avantajları ve Kullanım Alanları</h3>
<p>VRF sistemleri, çok sayıda avantaj sunarak, farklı alanlarda kullanım için ideal bir çözüm oluşturur. İşte VRF sisteminin en önemli avantajları:</p>
<h4>1. <strong>Enerji Verimliliği</strong></h4>
<p>VRF sistemleri, geleneksel klima ve ısıtma sistemlerine göre %30-50 daha verimlidir, bu da daha düşük enerji maliyetleri anlamına gelir.</p>
<h4>2. <strong>Esneklik</strong></h4>
<p>VRF sistemleri, küçük odalardan büyük ticari alanlara kadar farklı büyüklükteki binalarda uygulanabilir.</p>
<h4>3. <strong>Yüksek Konfor</strong></h4>
<p>Her odanın sıcaklığı bağımsız olarak ayarlanabilir, bu da tüm binada eşit konforlu bir ortam sağlar.</p>
<h4>4. <strong>Gelişmiş Kontrol Sistemleri</strong></h4>
<p>Akıllı kontrol sistemleri, kullanıcıların sistemin sıcaklık, nem ve enerji kullanımını kolayca izlemelerini sağlar.</p><p></p>
                                </div>`,
    image: "/assets/VRF-Sistemleri.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 9,
    title: "TÜPLÜ KOMBİ SİSTEMLERİ",
    description: `
      <h3>Tüplü Kombi Sistemleri Nedir?   </h3>

<p>Tüplü kombi sistemleri , doğalgaz şebekesine bağlı olmayan binalar için ideal bir ısıtma çözümüdür. Bu sistemler, LPG (Sıvılaştırılmış Petrol Gazı) tüpleri veya tanklar aracılığıyla çalışır ve mekanların hem ısıtılmasını hem de sıcak su ihtiyacını karşılar. Özellikle kırsal alanlar, yazlıklar, geçici yerleşim yerleri veya doğalgaz altyapısının bulunmadığı bölgelerde tercih edilen tüplü kombi sistemleri, konforlu ve verimli bir yaşam alanı sunar. </p>
<h3>Tüplü Kombi Sistemlerinin Çalışma Prensibi   </h3>

<p>Tüplü kombi sistemleri, geleneksel doğalgaz kombilerine benzer şekilde çalışır. Tek fark, enerji kaynağının doğalgaz yerine LPG gazı  olmasıdır. Kombi, LPG tüplerinden veya depolama tanklarından aldığı gazı yakarak suyu ısıtır. Isınan su, radyatörler veya yerden ısıtma sistemleri aracılığıyla mekanı ısıtırken, aynı zamanda sıcak su ihtiyacını da karşılar. Akıllı termostatlar ve modern teknolojilerle donatılan bu sistemler, enerji tüketimini optimize eder ve kullanıcı dostu bir deneyim sağlar. </p>
<h3>Tüplü Kombi Sistemlerinin Avantajları   </h3>

    <ol>
      <li><strong>Esneklik: </strong>
      Doğalgaz şebekesinin olmadığı bölgelerde bile kolayca kurulabilir. LPG tüpleri veya tanklarla çalıştığı için her türlü yerleşim biriminde kullanılabilir. 
      </li>
      <li><strong>Çok Fonksiyonlu: </strong>
      Hem ısıtma hem de sıcak su sağlama özelliğiyle, tek bir cihazla iki temel ihtiyacınızı karşılar. 
      </li>
      <li><strong>Enerji Verimliliği: </strong>
      Modern tüplü kombiler, yüksek verimlilik oranlarına sahiptir. Düşük gaz tüketimi ile maksimum ısıtma sağlar. 
      </li>
      <li><strong>Kolay Kurulum: </strong>
      Uzman ekipler tarafından hızlı ve sorunsuz bir şekilde kurulabilir. Mevcut ısıtma sistemlerinizle entegre edilebilir. 
      </li>
      <li><strong>Konforlu Kullanım: </strong>
      Akıllı termostatlar ve dijital kontrol panelleriyle, odalara özel sıcaklık ayarı yapabilir ve enerji tasarrufu sağlayabilirsiniz. 
      </li>
    </ol>
     

<h3>Tüplü Kombi Sistemlerinin Bileşenleri   </h3>

<ul>
      <li><strong>Kombi Cihazı:</strong>  LPG gazını yakarak suyu ısıtan ana ünite. Yüksek verimlilikli modeller, enerji tüketimini minimize eder.   </li>
      <li><strong>LPG Tüpü veya Tank:</strong>  Gazın depolandığı ve kombiye aktarıldığı bileşen. Tank sistemleri, daha uzun süreli ve kesintisiz kullanım sağlar.   </li>
      <li><strong>Boru ve Bağlantı Elemanları:</strong>  Gazın ve suyun kombiye güvenli bir şekilde taşınmasını sağlar.   </li>
      <li><strong>Radyatörler veya Yerden Isıtma Sistemleri:</strong>  Isınan suyu mekanlara dağıtan bileşenler.   </li>
      <li><strong>Termostatlar ve Kontrol Üniteleri:</strong>  Sıcaklık ayarlarını otomatikleştirerek enerji tüketimini optimize eder.   </li>
</ul>
     

<h3>Tüplü Kombi Sistemleri İçin Hangi Durumlarda Tercih Edilir?   </h3>

    <ul>
      <li><strong>Doğalgaz Altyapısının Olmadığı Bölgeler:</strong>  Kırsal alanlar, yazlıklar veya yeni yerleşim bölgelerinde idealdir.   </li>
      <li><strong>Geçici veya Mevsimsel Kullanım:</strong>  Yaz aylarında kullanılan yazlıklar veya geçici ikamet edilen mekanlar için ekonomik bir çözümdür.   </li>
      <li><strong>Mevcut Sistemlerin Modernizasyonu:</strong>  Eski ısıtma sistemlerinin yerine, daha verimli ve çevre dostu bir alternatif arayanlar için uygundur. </li>
    </ul>
     

<h3>Neden Profesyonel Tüplü Kombi Hizmeti Seçmelisiniz?   </h3>

<p>Tüplü kombi sistemlerinin doğru şekilde tasarlanması, kurulması ve bakımı, sistemin verimli ve güvenli çalışmasını sağlar. <b>AL-DE Mühendislik</b>  olarak:   </p>

    <ul>
      <li><strong>Uzman Ekip:</strong>  Tecrübeli mühendis ve teknisyenlerimiz, projenizin her aşamasında size destek olur.  </li>
      <li><strong>Kaliteli Malzemeler:</strong>  Dayanıklı ve verimli malzemeler kullanarak uzun ömürlü çözümler sunarız.   </li>
      <li><strong>Güvenlik ve Standartlar:</strong>  Tüm kurulumlarımız, ulusal ve uluslararası güvenlik standartlarına uygun şekilde gerçekleştirilir.   </li>
      <li><strong>Periyodik Bakım:</strong>  Düzenli bakım hizmetleri ile sisteminizin her zaman en iyi performansla çalışmasını sağlarız. </li>
    </ul>
     

<h3>Yatırım Yapmanın En İyi Yolu: Tüplü Kombi Sistemleri   </h3>
<br/>
<br/>
Tüplü kombi sistemleri, doğalgaz şebekesinin olmadığı bölgelerde konforlu ve sürdürülebilir bir yaşam sunar. Enerji verimliliği, kolay kurulum ve esneklik özellikleriyle, her türlü mekan için ideal bir çözümdür. <b>AL-DE Mühendislik</b>  olarak, tüplü kombi sistemlerinde uzmanlaşmış ekibimizle size danışmanlık, tasarım, kurulum ve bakım hizmetleri sunuyoruz.   
<br/>
<br/>
Konforunuzu yeniden tanımlayın – <b>AL-DE Mühendislik</b> ile!  
    `,
    image: "/assets/tuplu-kombi.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 10,
    title: "YERDEN ISITMA SİSTEMLERİ",
    description: `<div>
    <h3>Yerden Isıtma Tesisatı Nedir?  </h3>

<p>Yerden ısıtma tesisatı , mekanların zemininden ısı yayarak eşsiz bir konfor sağlayan modern bir ısıtma sistemidir. Geleneksel radyatörlerin aksine, bu sistem ısıyı doğrudan zeminden odaya homojen bir şekilde dağıtır. Böylece, başta sıcak – ayakta soğuk hissi ortadan kalkar ve mekan içinde dengeli bir sıcaklık deneyimi yaşarsınız. </p>

<h4>Bu sistem, hidronik (sulu)  veya elektrikli  olmak üzere iki temel prensiple çalışır:   </h4>

    <ul>
      <li><strong>Hidronik Sistemler:</strong>  Kazan veya ısı pompası ile ısıtılan su, zemin altına yerleştirilen borularda dolaşır.  </li>
      <li><strong>Elektrikli Sistemler:</strong>  Zemine monte edilen elektrikli ısıtıcı kablolar veya paspaslar aracılığıyla ısı sağlanır.</li>
    </ul>
     

<p>Yerden ısıtma tesisatı, özellikle enerji verimliliği, estetik tasarım ve sağlığa duyarlı ısıtma çözümleri sunar. Radyatör gibi görsel engeller içermediği için iç mekan tasarımı üzerinde sınırsız özgürlük tanır. Ayrıca, düşük sıcaklıkla çalıştığı için enerji tüketimini minimize eder ve çevreye olan etkisini azaltır. </p>
<h3>Neden Yerden Isıtma Tesisatı?  </h3>

    <ul>
      <li><strong>Enerji Tasarrufu:</strong>  Düşük su sıcaklıkları ile çalıştığı için %20-30’a varan enerji tasarrufu sağlar.  </li>
      <li><strong>Sağlıklı Ortam:</strong>  Hava sirkülasyonu yerine ışıma ile ısıtma yaptığı için toz, alerjen ve nem sorunlarını azaltır.  </li>
      <li><strong>Estetik Tasarım:</strong>  Radyatör veya diğer ısıtma elemanlarının bulunmaması, mekanlara ferah bir görünüm kazandırır.  </li>
      <li><strong>Sessiz Çalışma:</strong>  Fan veya motor içermediği için sessiz bir ortam sağlar.  </li>
      <li><strong>Uzun Ömürlü:</strong>  Kaliteli malzemelerle kurulan sistemler, 50+ yıl sorunsuz çalışabilir.</li>
    </ul>
     

<h4>Yerden Isıtma Tesisatının Çalışma Prensibi  </h4>

<p>
  Yerden ısıtma sistemi, zemin altına döşenen özel ısıtma boruları veya kablolar aracılığıyla çalışır. Bu bileşenler, kazan veya ısı pompasından gelen sıcak suyu veya elektrik enerjisini kullanarak zemini ısıtır. Isı, zeminden başlayarak yukarı doğru yayılır ve odanın her köşesinde eşit bir sıcaklık sağlar. Akıllı termostatlar ile kontrol edilen sistem, kullanıcıların ihtiyaçlarına göre programlanabilir ve enerji tüketimini optimize eder. 
  Profesyonel Çözümlerle Yerden Isıtma Tesisatı 
</p> 

Yerden ısıtma tesisatının kurulumu, uzmanlık gerektiren bir süreçtir. Doğru tasarım, kaliteli malzemeler ve profesyonel ekip, sistemin verimli ve uzun ömürlü olmasını sağlar. <b>AL-DE Mühendislik</b>  olarak, yerden ısıtma tesisatı projelerinizde size danışmanlık, tasarım, kurulum ve bakım hizmetleri sunuyoruz. Modern teknoloji ve müşteri odaklı yaklaşımımızla, konforlu ve sürdürülebilir bir yaşam alanı yaratmanıza yardımcı oluyoruz. 
        <h4>Avantajları</h4>
        <ul class="advantages-list">
            <li><strong>Homojen Isı Dağılımı:</strong> Isı, zeminden başlayarak odaya dengeli yayılır. Başta sıcak – ayakta soğuk hissinin önüne geçer.</li>
            <li><strong>Enerji Verimliliği:</strong> Düşük su sıcaklıkları ile çalışarak %20-30’a varan enerji tasarrufu sağlar.</li>
            <li><strong>Estetik Tasarım:</strong> Radyatör veya boruların görünmez olması, iç mekan tasarımında sınırsız özgürlük sunar.</li>
            <li><strong>Sağlık Dostu:</strong> Hava sirkülasyonu yerine ışıma ile ısıtma yaptığı için toz ve alerjenlerin havalandırılmasına katkıda bulunur.</li>
        </ul>
        <h4>Sistem Bileşenleri</h4>
        <ul class="components-list">
            <li><strong>Isıtma Boruları/Kabloları:</strong> Dayanıklı polietilen (PEX) veya paslanmaz çelik malzemeler kullanılır.</li>
            <li><strong>Kollektör ve Vana Grubu:</strong> Sıcaklık dağılımını dengeleyen kontrol ünitesi.</li>
            <li><strong>Isı Yalıtım Levhaları:</strong> Zemindeki ısı kayıplarını %90 oranında azaltır.</li>
            <li><strong>Akıllı Termostatlar:</strong> Odalara özel programlanabilir sıcaklık ayarı.</li>
        </ul>
        <h4>Sistem Türleri</h4>
        <ul>
            <li><strong>Sulu (Hidronik) Sistemler:</strong>Kazan veya ısı pompası ile ısıtılan su, borularda dolaşarak ısıyı taşır. <em>Uzun ömürlü ve düşük maliyetli.</em></li>
            
            <li><strong>Elektrikli Sistemler :</strong>Elektrikli kablolar veya paspaslar ile anlık ısıtma. <em>Küçük alanlar ve hızlı montaj için ideal.</em></li>
        </ul>
        <h4>Kurulum Süreci</h4>
        <ol>
            <li><strong>Proje Tasarımı:</strong> 3D modelleme ile ısı ihtiyacı analizi</li>
            <li><strong>Zemin Hazırlığı:</strong> Isı yalıtım levhalarının döşenmesi</li>
            <li><strong>Boru Yerleştirme:</strong> Belirlenen desene göre sabitleme</li>
            <li><strong>Sistem Testi:</strong> Su veya elektrik ile basınç testi</li>
        </ol>
    </div>`,
    image: "/assets/yerden-isitma-sistemleri.webp", // Görsel dosya yolunu güncelle
  },
];
export const loader = async () => {
  return {
    services: MekanikTesisatListesi,
    references: references
  }
}
export const meta: MetaFunction = () => {
  return [
    { title: "Al-De Mühendislik | Hizmetlerimiz" },
  ]
}
export default function Page() {
  const { services, references } = useLoaderData<typeof loader>();
  return (
    <div className="pt-[122px]">
      <div className="">
        <Container className="flex-wrap">
          <MekanikTesisatlarComponent services={services} />
        </Container>
      </div>
      <Slogans />
      <References references={references} />
    </div>
  )
}
